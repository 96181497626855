import React from "react";
import {
  Box,
  Button,
  Container,
  Flex,
  Heading,
  Stack,
  Text,
  VStack,
  Image,
  SimpleGrid,
} from "@chakra-ui/react";
import { toaster } from "../components/ui/toaster";
import emailjs from "emailjs-com";

import heroImage from "../assets/images/69-Online-Sale.svg";
import Navbar from "../components/custom/Navbar";
import { Toaster } from "../components/ui/toaster";

// Import placeholder images for the how it works section
import step1Image from "../assets/images/256-Inspired-With-Ideas.svg";
import step2Image from "../assets/images/227-Brainstorming.svg";
import step3Image from "../assets/images/154-Investment.svg";

import ContactForm from "../components/custom/ContactForm";
import Footer from "../components/custom/Footer";

const Landing = () => {
  const scrollToContactForm = () => {
    const contactForm = document.getElementById("contact-form");
    if (contactForm) {
      contactForm.scrollIntoView({ behavior: "smooth" });
    }
  };

  const sendEmail = (values) => {
    emailjs
      .send("YOUR_SERVICE_ID", "YOUR_TEMPLATE_ID", values, "YOUR_USER_ID")
      .then(
        (result) => {
          console.log(result.text);
          toaster.create({
            title: "Success",
            description: "Your message has been sent. We'll be in touch soon!",
            type: "success",
            duration: 5000,
            isClosable: true,
          });
        },
        (error) => {
          console.log(error.text);
          toaster.create({
            title: "Error",
            description:
              "There was an error sending your message. Please try again.",
            type: "error",
            duration: 5000,
            isClosable: true,
          });
        }
      );
  };

  return (
    <Box bg="white">
      <Navbar onGetStartedClick={scrollToContactForm} />

      <Container maxW="container.xl">
        {/* Hero Section */}
        <Flex
          align="center"
          justify={{ base: "center", md: "space-between" }}
          direction={{ base: "column", md: "row" }}
          minH="70vh"
          px={8}
          mb={{ base: 20, md: 0 }}
        >
          <Box w={{ base: "full", md: "50%" }}>
            <Image src={heroImage} alt="Hero Image" />
          </Box>
          <Stack spacing={4} w={{ base: "full", md: "45%" }}>
            <Heading
              as="h1"
              size="5xl"
              fontWeight="bold"
              color="black"
              textAlign={{ base: "center", md: "left" }}
            >
              Fundraising, reimagined.
            </Heading>
            <Text
              fontSize="xl"
              color="gray.600"
              textAlign={{ base: "center", md: "left" }}
            >
              Effortlessly manage and sell exclusive discounts from local
              businesses, making fundraising simple and rewarding for your
              community.
            </Text>

            <Button
              size="lg"
              w={{ base: "full", sm: "auto" }}
              alignSelf={{ base: "center", md: "flex-start" }}
              onClick={scrollToContactForm}
              color="white"
              bg="black"
              _hover={{
                bg: "#18181a",
              }}
              mt={2}
            >
              Start Fundraising
            </Button>

            <Text
              fontSize="sm"
              color="gray.600"
              textAlign={{ base: "center", md: "left" }}
            >
              No credit card required.
            </Text>
          </Stack>
        </Flex>

        {/* How It Works Section */}
        <Box mb={{ base: 6, md: 16 }}>
          <Heading
            as="h1"
            size="2xl"
            fontWeight="bold"
            textAlign="center"
            color="black"
          >
            How It Works
          </Heading>
          <Box
            width="50px"
            height="3px"
            backgroundColor="black"
            margin="10px auto 20px"
          />
          <SimpleGrid columns={{ base: 1, md: 3 }} spacing={10}>
            {[
              {
                image: step1Image,
                title: "Create Your Campaign",
                description:
                  "Set up your fundraising campaign in minutes. Add businesses, team members, and manage and track your campaign all from a simple dashboard.",
              },
              {
                image: step2Image,
                title: "Engage Local Businesses",
                description:
                  "Partner with local businesses to offer exclusive discounts. Easily add and manage your partner businesses, all in one place.",
              },
              {
                image: step3Image,
                title: "Raise Funds",
                description:
                  "Sell your virtual coupon book within your community. We manage the distribution and management of the coupons, so you can focus on reaching your goals.",
              },
            ].map((step, index) => (
              <VStack key={index} spacing={4} align="center">
                <Image
                  src={step.image}
                  alt={`Step ${index + 1}`}
                  boxSize="300px"
                />
                <Heading as="h3" size="lg" color="black">
                  {step.title}
                </Heading>
                <Text textAlign="center" color="gray.600" px={10}>
                  {step.description}
                </Text>
              </VStack>
            ))}
          </SimpleGrid>
        </Box>
        <Box pt={16} pb={24}>
          <Heading
            as="h1"
            size="2xl"
            fontWeight="bold"
            textAlign="center"
            color="black"
          >
            Contact Us
          </Heading>
          <Box
            width="50px"
            height="3px"
            backgroundColor="black"
            margin="10px auto 20px"
            mb={12}
          />
          <Text textAlign="center" color="gray.600">
            Let's chat about how we can help you raise funds for your cause.
          </Text>
          <ContactForm sales={true} onSubmit={sendEmail} />
        </Box>
      </Container>

      <Footer />
      <Toaster />
    </Box>
  );
};

export default Landing;
